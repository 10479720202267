'use client'

import axios from 'axios'
import { memo, PropsWithChildren, useEffect, useMemo, useState } from 'react'

import useDrawerState from '@/hooks/context/useDrawerState'
import { useDebounce } from '@/hooks/useDebounce'
import useThread from '@/hooks/useThread'

import { configHeader } from '@/constants/api'
import { API_URL } from '@/constants/env'

import { ThreadContext } from './thread.context'

import { IQuestion } from '@/types/chatbot'
import { Section } from '@/types/document'

export const ThreadProvider = memo(({ children }: PropsWithChildren) => {
  const [questions, setQuestions] = useState<IQuestion[]>([])
  const [steps, setSteps] = useState<{ [key: string]: any }>({})
  const [currentStep, setCurrentStep] = useState<number>(0)
  const [{ sections, history, historyIndex }, setSections] = useState<{
    sections?: Section[]
    history: Section[][]
    historyIndex: number
  }>({
    sections: undefined,
    history: [],
    historyIndex: 0,
  })
  const { selectedConversation, mutateConversations } = useDrawerState()
  const { thread } = useThread(selectedConversation)

  useEffect(() => {
    if (thread) {
      setCurrentStep(thread.currentStep)
      setQuestions(thread.questions)
      if (
        thread.currentStep > 4 &&
        (!thread.steps[5]?.sections ||
          thread.steps[5]?.initialSectionsStatus !== 'enhancedTwice')
      ) {
        if (thread.steps[thread.currentStep]?.numQuestions) {
          setQuestions(thread.questions.slice(0, -1))
        }
        setCurrentStep(4)
        setSections({
          sections: undefined,
          history: [],
          historyIndex: 0,
        })
        setSteps({ ...thread.steps, 4: undefined })
      } else {
        if (thread.steps) {
          setSections({
            sections: thread.steps[5]?.sections,
            history: thread.steps[5]?.sections
              ? [thread.steps[5]?.sections]
              : [],
            historyIndex: 0,
          })
        }
        setSteps(thread.steps)
      }
    } else {
      setSteps({})
      setCurrentStep(0)
      setQuestions([])
      setSections({
        sections: undefined,
        history: [],
        historyIndex: 0,
      })
    }
  }, [thread, selectedConversation])

  const handleUndo = () => {
    if (
      history === undefined ||
      historyIndex === undefined ||
      historyIndex === 0
    ) {
      return
    }
    setSections(({ history: prevHistory, historyIndex: prevHistoryIndex }) => {
      const newSections = prevHistory[prevHistoryIndex - 1]
      setSteps({
        ...steps,
        5: {
          ...steps[5],
          sections: newSections,
        },
      })
      return {
        sections: newSections,
        history: prevHistory,
        historyIndex: prevHistoryIndex - 1,
      }
    })
  }

  const handleRedo = () => {
    if (
      history === undefined ||
      historyIndex === undefined ||
      historyIndex === history.length - 1
    ) {
      return
    }
    setSections(({ history: prevHistory, historyIndex: prevHistoryIndex }) => {
      const newSections = prevHistory[prevHistoryIndex + 1]
      setSteps({
        ...steps,
        5: {
          ...steps[5],
          sections: newSections,
        },
      })
      return {
        sections: newSections,
        history: prevHistory,
        historyIndex: prevHistoryIndex + 1,
      }
    })
  }

  const isUndoDisabled = useMemo(
    () => historyIndex === undefined || historyIndex === 0,
    [historyIndex]
  )
  const isRedoDisabled = useMemo(
    () =>
      history === undefined ||
      historyIndex === undefined ||
      historyIndex === history.length - 1,
    [historyIndex, history]
  )

  /* 
    Everytime the currentStep or steps data changes, store the thread to the database
    Exception: when the currentStep is 0, because the conversation is not yet created.
    Therefore we call storeToDb() in the useEffect in the AgentResponseStep component
  */
  useEffect(() => {
    if (currentStep > 0 && !!steps && !!selectedConversation) {
      debouncedStoreToDb()
    }
  }, [currentStep, steps])

  const storeToDb = async () => {
    const config = {
      method: 'post',
      withCredentials: true,
      ...configHeader,
      data: JSON.stringify({
        id: selectedConversation,
        data: JSON.stringify({
          currentStep,
          steps,
          questions,
        }),
      }),
    }

    await axios(`${API_URL}/thread`, config)
  }

  const debouncedStoreToDb = useDebounce(() => {
    storeToDb().then(() => mutateConversations())
  }, 500)

  const contextValue = useMemo(() => {
    return {
      questions,
      steps,
      currentStep,
      history,
      historyIndex,
      sections,
      isUndoDisabled,
      isRedoDisabled,
      storeToDb,
      setCurrentStep,
      setSections,
      setSteps,
      setQuestions,
      handleUndo,
      handleRedo,
    }
  }, [
    questions,
    steps,
    currentStep,
    sections,
    history,
    historyIndex,
    isUndoDisabled,
    isRedoDisabled,
    mutateConversations,
  ])

  return (
    <ThreadContext.Provider value={contextValue}>
      {children}
    </ThreadContext.Provider>
  )
})
